<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <v-card class="shadow-off" loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text class="pb-0">
          <div class="d-flex align-center mb-2" v-if="!loading || metadata._id">
            <div class="mr-2">
              <v-chip v-if="stage === 3" small label class="border-on radius-small white--text" color="btnColor">Closed</v-chip>
              <v-chip v-else-if="stage === 1" small label class="border-on radius-small white--text" color="btnColor">Pending for review</v-chip>
              <v-chip v-else small label class="border-on radius-small white--text" color="btnColor">Open</v-chip>
            </div>
            <div class="">
              <v-chip v-if="metadata.managerlist.indexOf(userid) > -1" small label color="#f4f4f4" class="border-on">Manager</v-chip>
              <v-chip v-else small label color="">General</v-chip>
            </div>
            <v-btn class="shadow-off ml-2" small icon @click="refreshData()"
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
            <v-tooltip bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <lb-activitylogwithicon :config="activitylogconfig" :url="`/v2/financialclose/analysis/gettrialbalanceuplodelogs/${metadata._id}`" />
                </span>
              </template>
              <span>Activity Log</span>
          </v-tooltip>
            <v-spacer></v-spacer>
          </div>
          <div v-if="loading && !metadata._id">
            <v-skeleton-loader
              type="table-heading, list-item-two-line, article, article"
            ></v-skeleton-loader>
          </div>
          <div class="mb-4" v-else>
            <div class="flex-grow-1 d-block" >
              <div class="px-0 pt-0" v-if="tb.length > 0">
                <div
                  class="d-flex align-center"
                  v-if="metadata.stage === 0 && metadata.managerlist.indexOf(userid) > -1"
                >
                  <span class="mr-4">Trial Balance Changed?</span>
                  <!-- <lb-file
                    v-model="tbfile"
                    label=""
                    class="py-0 my-0"
                    :loading="fileprocessing['__tb'] === 1"
                    :drag="false"
                    hidedetails
                    @change="readTBFile('__tb')"
                    :displayname="false"
                    accept=".xlsx,.xls,.xlsm,.xlsb"
                  />
                  <v-btn
                    color="primary"
                    small
                    class="ml-2"
                    @click="
                      $nova.downloadFile(
                        '',
                        {},
                        '/v2/financialclose/analysis/getuploadtbtemplate'
                      )
                    "
                    >Download Template</v-btn
                  > -->

                <v-btn small color="fbcPrimary" class="mr-1 white--text" v-if="($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))" @click="tbuploaddata = []; tbfile = null; tbdialog = true ; tberrorflag=false">
                  <v-icon class="mr-1">mdi-upload-multiple</v-icon>
                  <span>Bulk Upload</span>
                </v-btn>
                <lb-dailogboxnew v-model="tbdialog" :width="(tbuploaddata.length > 0) ? '' : '400'"
                  heading="Bulk TrialBalance Upload" :loading="loading">
                  <template v-slot:body>
                    <div>
                      <div class="text-center mb-1">Upload the bulk upload trialbalance template here.</div>
                      <div class="d-flex align-center justify-center">
                        <lb-file
                          v-model="tbfile"
                          label=""
                          class="py-0 my-0"
                          :loading="fileprocessing['__tb'] === 1"
                          :drag="false"
                          hidedetails
                          @change="readTBFile('__tb')"
                          :displayname="false"
                          accept=".xlsx,.xls,.xlsm,.xlsb"
                              />
                        <v-btn
                          color="btnColor"
                          small
                          class="ml-2 white--text"
                          @click="$nova.downloadFile('',{},'/v2/financialclose/analysis/getuploadtbtemplate')"
                          >Download Template</v-btn
                        >
                      </div>
                    </div>        
                    <div v-if="tbuploaddata.length > 0" class="mt-3 radius-all">
                      <v-simple-table dense class="FC-Table">
                          <template v-slot:default>
                            <thead>
                              <th class="text-left">GL Code</th>
                              <th class="text-right">Opening Amount</th>
                              <th class="text-right">Transaction</th>
                              <th class="text-right">Audit Entries</th>
                              <th class="text-right">Closing Amount</th>
                              <th class="text-right">{{$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'}}</th>
                            </thead>
                            <v-slide-y-transition
                              class=""
                              group
                              tag="tbody"
                              style="overflow: hidden !important"
                            >
                              <tr v-for="(v, k) in tbuploaddata" :key="k" class="">
                                <td
                                  v-if="((tbdataerror[k] || [])[0] || [])[1] !== ''"
                                  class="error--text font-weight-bold"
                                >
                                  <v-tooltip bottom content-class="tooltip-bottom">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        {{ (v.data[0] || {}).glcode || "Error" }}
                                      </div>
                                    </template>
                                    <span>{{ ((tbdataerror[k] || [])[0] || [])[1] }}</span>
                                  </v-tooltip>
                                </td>
                                <td class="" v-else>{{ (v.data[0] || {}).glcode }}</td>
                                <td class="text-right">
                                  {{ $nova.formatNumber((v.data[0] || {}).opening_amount) }}
                                </td>
                                <td class="text-right">
                                  {{ $nova.formatNumber((v.data[0] || {}).transaction) }}
                                </td>
                                <td class="text-right">
                                  {{ $nova.formatNumber((v.data[0] || {}).audit_entries) }}
                                </td>
                                <td class="text-right" v-if="(v.data[0] || {}).closing_amount">
                                  {{ $nova.formatNumber((v.data[0] || {}).closing_amount) }}
                                </td>
                                <td v-else class="text-right error--text font-weight-bold">
                                  <v-tooltip bottom content-class="tooltip-bottom">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                         Error
                                      </div>
                                    </template>
                                    <span>Closing amount requried</span>
                                  </v-tooltip>
                                </td>
                                <td class="text-right">
                                  {{((v.data[0] || {}).location) }}
                                </td>
                              </tr>
                            </v-slide-y-transition>
                          </template>
                        </v-simple-table>
                    </div>
                  </template>
                  <template v-slot:actions>
                      <v-spacer></v-spacer>
                      <div class="ml-2 error--text font-weight-bold" v-if="tberrorflag">
                        We found few errors. Rectify the same to proceed to upload
                      </div>
                      <v-btn
                        small
                        class="ml-2 white--text"
                        color="fbcPrimary"
                        v-else-if="tbuploaddata.length > 0"
                        @click="uploadTBData()"
                        >Verify & Upload</v-btn
                      >
                    </template>
                </lb-dailogboxnew>
                  <v-spacer></v-spacer>
                  <v-chip v-if="tb.length === 0" color="info" label small
                    >Pending</v-chip
                  >
                  <v-chip v-else color="success" label small>Uploaded</v-chip>
                </div>
                <div  class="d-flex align-center mt-2" v-if="Math.round(closingbalancetotal) !== 0"><span class="mr-2">Closing Balance Difference :</span> 
                <span v-if="Math.round(closingbalancetotal) !== 0" class="red--text subtitle-1">{{ $nova.formatNumber(closingbalancetotal)}}</span>   
                </div>
                <DataTable v-if="formatedData.length > 0"
                  :headers="headers"
                  :items="formatedData"
                  :enableslot="['reference','action','location']"
                  :loading="loading"
                  :bgColor="true"
                >
                  <template v-slot:reference="{ item }">
                    <div
                      class="" v-if="(item.reference_detail || []).length > 0"
                    >
                     <v-menu
                      open-on-hover 
                      left
                      offset-x
                      v-for="(v, k) in item.reference_detail"
                      :key="k"
                      :max-width="400"
                      elevation-0
                      
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          small
                          outlined
                          class="pl-2 mb-1 mr-1"
                        >
                          <v-icon>mdi-tag</v-icon>
                          <div class="pl-1">{{ v.ref }}</div>
                        </v-chip>
                      </template>
                      <v-card>
                        <v-card-text class="py-2 px-4">{{ v.description }}</v-card-text>
                      </v-card>
                    </v-menu>
                    </div>
                    <div class="" v-else>Not linked</div>
                  </template>
                   <template v-slot:location="{ item }">
                    {{item.location[0]}}
                  </template>
                  <template v-slot:action="{ item }">
                  <div class="d-flex align-center justify-center">
                    <v-tooltip
                      bottom
                      content-class="tooltip-bottom"
                      v-if="metadata.managerlist.indexOf(userid) > -1 && metadata.stage === 0 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          small
                          v-bind="attrs"
                          v-on="on"
                          @click="addEditForm(item)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <lb-activitylogwithicon :config="activitylogconfig" :url="`/v2/financialclose/analysis/gettrialbalancelogs/${item._id}`" />
                      </span>
                    </template>
                    <span>Activity Log</span>
                    </v-tooltip>
                  </div>
                </template>
                </DataTable>
                <div v-else class="grey--text px-4 mb-2">No Data</div>
              </div>
              <div v-else class="pa-0">
                <div class="d-flex align-center pa-2" >
                  <span class="mr-4" v-if="metadata.managerlist.indexOf(userid) > -1">Trial Balance</span>
                    <v-btn small color="btnColor" class="mr-1" @click="tbuploaddata = []; tbfile = null; tbdialog = true;tberrorflag=false" v-if="metadata.managerlist.indexOf(userid) > -1">
                  <v-icon class="mr-1">mdi-upload-multiple</v-icon>
                  <span>Bulk Upload</span>
                </v-btn>
                <lb-dailogboxnew v-model="tbdialog" :width="(tbuploaddata.length > 0) ? '' : '400'"
                  heading="Bulk TrialBalance Upload" :loading="loading">
                  <template v-slot:body>
                    <div>
                      <div class="text-center mb-1">Upload the bulk upload trialbalance template here.</div>
                      <div class="d-flex align-center justify-center">
                        <lb-file
                          v-model="tbfile"
                          label=""
                          class="py-0 my-0"
                          :loading="fileprocessing['__tb'] === 1"
                          :drag="false"
                          hidedetails
                          @change="readTBFile('__tb')"
                          :displayname="false"
                          accept=".xlsx,.xls,.xlsm,.xlsb"
                          v-if="metadata.managerlist.indexOf(userid) > -1"
                              />
                        <v-btn v-if="metadata.managerlist.indexOf(userid) > -1"
                          color="fbcPrimary"
                          small
                          class="ml-2"
                          @click="$nova.downloadFile('',{},'/v2/financialclose/analysis/getuploadtbtemplate')"
                          >Download Template</v-btn
                        >
                      </div>
                    </div>        
                    <div v-if="tbuploaddata.length > 0" class="mt-3 radius-small">
                      <v-simple-table dense class="FC-Table">
                          <template v-slot:default>
                            <thead>
                              <th class="text-left">GL Code</th>
                              <th class="text-right">Opening Amount</th>
                              <th class="text-right">Transaction</th>
                              <th class="text-right">Audit Entries</th>
                              <th class="text-right">Closing Amount</th>
                              <th class="text-right">{{$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'}}</th>
                            </thead>
                            <v-slide-y-transition
                              class=""
                              group
                              tag="tbody"
                              style="overflow: hidden !important"
                            >
                              <tr v-for="(v, k) in tbuploaddata" :key="k" class="">
                                <td
                                  v-if="((tbdataerror[k] || [])[0] || [])[1] !== ''"
                                  class="error--text font-weight-bold"
                                >
                                  <v-tooltip bottom content-class="tooltip-bottom">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        {{ (v.data[0] || {}).glcode || "Error" }}
                                      </div>
                                    </template>
                                    <span>{{ ((tbdataerror[k] || [])[0] || [])[1] }}</span>
                                  </v-tooltip>
                                </td>
                                <td class="" v-else>{{ (v.data[0] || {}).glcode }}</td>
                                <td class="text-right">
                                  {{ $nova.formatNumber((v.data[0] || {}).opening_amount) }}
                                </td>
                                <td class="text-right">
                                  {{ $nova.formatNumber((v.data[0] || {}).transaction) }}
                                </td>
                                <td class="text-right">
                                  {{ $nova.formatNumber((v.data[0] || {}).audit_entries) }}
                                </td>
                                <td class="text-right">
                                  {{ $nova.formatNumber((v.data[0] || {}).closing_amount) }}
                                </td>
                                <td class="text-right">
                                  {{((v.data[0] || {}).location) }}
                                </td>
                              </tr>
                            </v-slide-y-transition>
                          </template>
                        </v-simple-table>
                    </div>
                  </template>
                  <template v-slot:actions>
                      <v-spacer></v-spacer>
                      <div class="ml-2 error--text font-weight-bold" v-if="tberrorflag">
                        We found few errors. Rectify the same to proceed to upload
                      </div>
                      <v-btn
                        small
                        class="ml-2"
                        color="fbcPrimary"
                        v-else-if="tbuploaddata.length > 0"
                        @click="uploadTBData()"
                        >Verify & Upload</v-btn
                      >
                    </template>
                </lb-dailogboxnew>


                  <!-- <lb-file v-if="metadata.managerlist.indexOf(userid) > -1"
                    v-model="tbfile"
                    label=""
                    class="py-0 my-0"
                    :loading="fileprocessing['__tb'] === 1"
                    :drag="false"
                    hidedetails
                    @change="readTBFile('__tb')"
                    :displayname="false"
                    accept=".xlsx,.xls,.xlsm,.xlsb"
                  />
                  <v-btn v-if="metadata.managerlist.indexOf(userid) > -1"
                    color="fbcPrimary"
                    small
                    class="ml-2"
                    @click="
                      $nova.downloadFile(
                        '',
                        {},
                        '/v2/financialclose/analysis/getuploadtbtemplate'
                      )
                    "
                    >Download Template</v-btn
                  > -->
                  <v-spacer></v-spacer>
                  <v-chip v-if="tb.length === 0" color="info" label small
                    >Pending</v-chip
                  >
                  <v-chip v-else color="success" label small>Uploaded</v-chip>
                </div>
                <v-divider></v-divider>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <!-- <lb-dialog
        v-model="tbdialog"
        heading="Verify & Upload Trial Balance"
        width="1000"
        :loading="tbloading"
      >
        <template v-slot:body>
          <div v-if="tbuploaddata.length > 0">
            <v-simple-table dense class="">
              <template v-slot:default>
                <thead>
                  <th class="text-left">GL Code</th>
                  <th class="text-right">Opening Amount</th>
                  <th class="text-right">Transaction</th>
                  <th class="text-right">Audit Entries</th>
                  <th class="text-right">Closing Amount</th>
                  <th class="text-right">Location</th>
                </thead>
                <v-slide-y-transition
                  class=""
                  group
                  tag="tbody"
                  style="overflow: hidden !important"
                >
                  <tr v-for="(v, k) in tbuploaddata" :key="k" class="">
                    <td
                      v-if="((tbdataerror[k] || [])[0] || [])[1] !== ''"
                      class="error--text font-weight-bold"
                    >
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            {{ (v.data[0] || {}).glcode || "Error" }}
                          </div>
                        </template>
                        <span>{{ ((tbdataerror[k] || [])[0] || [])[1] }}</span>
                      </v-tooltip>
                    </td>
                    <td class="" v-else>{{ (v.data[0] || {}).glcode }}</td>
                    <td class="text-right">
                      {{ $nova.formatNumber((v.data[0] || {}).opening_amount) }}
                    </td>
                    <td class="text-right">
                      {{ $nova.formatNumber((v.data[0] || {}).transaction) }}
                    </td>
                    <td class="text-right">
                      {{ $nova.formatNumber((v.data[0] || {}).audit_entries) }}
                    </td>
                    <td class="text-right">
                      {{ $nova.formatNumber((v.data[0] || {}).closing_amount) }}
                    </td>
                    <td class="text-right">
                      {{((v.data[0] || {}).location) }}
                    </td>
                  </tr>
                </v-slide-y-transition>
              </template>
            </v-simple-table>
          </div>
          <div v-else class="grey--text py-4 text-center body-1">
            No data found in the uploaded file
          </div>
        </template>
         <template v-slot:actions>
          <v-spacer></v-spacer>
          <div class="ml-2 error--text font-weight-bold" v-if="tberrorflag">
            We found few errors. Rectify the same to proceed to upload
          </div>
          <v-btn
            small
            class="ml-2"
            color="fbcPrimary"
            v-else-if="tbuploaddata.length > 0"
            @click="uploadTBData()"
            >Verify & Upload</v-btn
          >
        </template>
      </lb-dialog> -->


       <lb-dailogboxnew  v-model="tasklistdilog" heading="Tasklist Reference"  :loading="tasklistdilogloading" width="600" >
       <template v-slot:body>
        <v-row>
          <v-col cols="6">
            <div class="">
              <span class="font-weight-bold mr-3">GL Code:</span>
              <span>{{ tasklistitem.glcode }}</span>
            </div>
            <div class="">
              <span class="font-weight-bold mr-3">Type:</span>
              <span>{{ tasklistitem.type }}</span>
            </div>
            <div class="">
              <span class="font-weight-bold mr-3">Transaction:</span>
              <span>{{ $nova.formatNumber(tasklistitem.transaction) }}</span>
            </div>
            <div class="">
              <span class="font-weight-bold mr-3">Closing Amount:</span>
              <span>{{ $nova.formatNumber(tasklistitem.closing_amount) }}</span>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="">
              <span class="font-weight-bold mr-3">Name:</span>
              <span>{{ tasklistitem.name }}</span>
            </div>
            <div class="">
              <span class="font-weight-bold mr-3">Opening Amount:</span>
              <span>{{  $nova.formatNumber(tasklistitem.opening_amount) }}</span>
            </div>
            <div class="">
              <span class="font-weight-bold mr-3">Audit Entries:</span>
              <span>{{ $nova.formatNumber(tasklistitem.audit_entries) }}</span>
            </div>
             <div  v-if="tasklistitem.location && tasklistitem.location.length > 0">
              <span class="font-weight-bold mr-3" >{{$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'}}:</span>
              <span>{{ tasklistitem.location[0] }}</span>
            </div>
          </v-col>
          </v-row>
            <div class="d-flex align-center mb-2 mt-2">
              <span class="mr-2 font-weight-bold">Tasklist Reference:</span><v-btn class="shadow-off" @click="openSelectGlDialog(tasklistitem.reference_detail,tasklistitem.location_id)" small text><v-icon class="mr-1">mdi-plus</v-icon>Add Reference</v-btn>
            </div>
            <div class="radius-all">
              <v-simple-table dense class="radius-all FC-Table" v-if="(tasklistitem.reference_detail|| []).length > 0">
                <thead>
                  <tr>
                    <th scope="Name">Reference</th>
                    <th scope="columns">Description</th>
                    <th scope="Action">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(v, k) in (tasklistitem.reference_detail || [])">
                    <tr :key="k">
                      <td>{{ v.ref }}</td>
                      <td>{{ v.description }}</td>
                      <td>
                        <v-icon class="ma-1" color="error" @click="removeRef(v)">mdi-delete</v-icon>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </v-simple-table>
              <div v-else class="d-flex grey--text  text-center text-caption">
                No task linked 
              </div>
            </div>
    
      
       </template>
       <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small class="ml-2 white--text" color="btnColor" @click="addUpdateGls(tasklistitem.reference_detail,tasklistitem._id)">Update</v-btn>
        </template>

       </lb-dailogboxnew>


       <!-- <lb-dialog v-model="selectgldialog" heading="Select GL(s) to update" width="600" persistent>
        <template v-slot:body>
          <div>
            <div class="mb-2">
              <lb-string label="Search" v-model="searchcustom" hidedetails />
            </div>
              <div v-for="(categoryItems, category) in groupedItems" :key="category">
                 <h2>{{ category }}</h2>
                <div v-for="(v,index) in categoryItems" :key="index">
                 <div  v-if="(v.ref + v.category).replaceAll(' ', '').toLowerCase().indexOf(searchcustom.toLowerCase()) > -1">
                    <div class="d-flex pl-2">
                      <v-checkbox class="mt-0 pt-0 mr-1" dense label="" v-model="selectgls" multiple :value="v.ref"
                      hide-details></v-checkbox>
                      <span class="mr-1">{{ v.ref }}</span>-
                      <span class="mr-1">{{ v.description }}</span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small class="ml-2" color="fbcPrimary" @click="addSelectedGls()">Select</v-btn>
        </template>
      </lb-dialog> -->



      <lb-dailogboxnew
      v-model="selectgldialog"
      :heading="'Select GL(s)'"
      width="600"
      :loading="selectgldialogloading"
    >
      <template v-slot:body>
        <div>
          <v-row>
              <v-col cols="12" class="my-0 py-1">
              <lb-dropdown
                v-model="selectgls"
                label="Tasklist*"
                itemtext="displayText"
                itemvalue="_id"
                multiple
                :items="locationtaskList"
              />
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:actions>
        <span class="caption">*Required</span>
        <v-spacer></v-spacer>
        <v-btn small color="fbcPrimary" @click="addSelectedGls()">
          <span>Add</span>
        </v-btn>
      </template>
    </lb-dailogboxnew>
    <lb-dailogboxnew v-model="isFirst" width="500">
          <template v-slot:body>
            <div class="">
              <v-row>
                <v-col>
                  <div class="text-body-1">Do you want to set up the Trial Balance module for a seamless reconciliation experience?</div>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:actions>
            <v-spacer></v-spacer>
            <div class="">                            
              <v-row>
                <v-col class="ma-2 pa-2">
                  <v-btn small @click="$nova.gotoLink({ path: '/financialclose/project/checklist/'+id})">
                    <span>Skip this for now</span>
                  </v-btn>
                  <v-btn small color="fbcPrimary " @click="setUpTrailBalance()">
                    <span>Yes</span>
                  </v-btn>
                </v-col>
              </v-row>
            </div> 
          </template>
        </lb-dailogboxnew>
    </div>
  </div>
</template>

<script>
import DataTable from "../../../../views/common/DataTable.vue";
export default {
  name: "financialclose_project_trialbalance",
  // props: ['props'],
  data: function () {
    return {
      right:"financialclose_project",
      metadata: {},
      tb: [],
      checklist: [],
      reflist: [],
      summary: {},
      pbcdata: {},
      // pbc: {},
      fileprocessing: {},
      fileprocessingpercent: {},
      userid: "",
      formdata: {},
      userlist: {},
      stage: -1,
      id: "",
      loading: false,
      tbfile: null,
      tbdialog: false,
      tbloading: false,
      tberrorflag: false,
      tbuploaddata: [],
      tbdataerror: [],
      tbtableheader: [
        { text: "GL Code", value: "glcode" },
        { text: "Tasklist Reference", value: "reference" },
        { text: "Type", value: "type" },
        { text: "Name", value: "name" },
        { text: "Opening Amount", value: "opening_amount", align: "right" },
        { text: "Transaction", value: "transaction", align: "right" },
        { text: "Audit Entries", value: "audit_entries", align: "right" },
        { text: "Closing Amount", value: "closing_amount", align: "right" },
        // { text: 'Action', value: 'action', align: "center", sortable: false },
      ],
      headers: [
        {
          value: "indexno",
          text: "SL No",
          sortable: false,
          filterable: false,
        },
        {
          value: "glcode",
          text: "GL Code",
          sortable: true,
          filterable: true,
        },
        {
          value: "reference",
          text: "Tasklist Reference",
        },
        {
          value: "location",
          text: this.$store.state.LocationHeader ? this.$store.state.LocationHeader  :'Location',
          filterable: true,
        },
        {
          value: "type",
          text: "Type",
          sortable: true,
          filterable: true,
        },
        {
          value: "name",
          text: "Name",
          sortable: true,
          filterable: true,
        },
        {
          value: "opening_amount",
          text: "Opening Amount",
          sortable: true,
          filterable: true,
          datatype: "number",
        },
        {
          value: "transaction",
          text: "Transaction",
          sortable: true,
          filterable: true,
          datatype: "number",
        },
        {
          value: "audit_entries",
          text: "Audit Entries",
          sortable: true,
          filterable: true,
          datatype: "number",
        },
        {
          value: "closing_amount",
          text: "Closing Amount",
          sortable: true,
          filterable: true,
          datatype: "number",
        },
        {
          value: "created_at",
          text: "Created At",
          sortable: false,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "text-center",
        },
      ],
      formatedData: [],
      tasklistdilog:false,
      tasklistdilogloading:false,
      tasklistitem:{},
      selectgldialog:false,
      selectgldialogloading:false,
      searchcustom: "",
      selectgls: [],
      selectRefIds:[],
      activitylogconfig: {
        trialbalanceupdated: {
          icon: "mdi-pencil",
          color: "fbcPrimary",
          displaytext: "Updated",
        },
        tbadd: {
          icon: "mdi-table-plus",
          color: "fbcPrimary",
          displaytext: "Trial Balance Added",
        },
      },
      selectchecklist_id:"",
      locationtaskList:[],
      isFirst:false,
    };
  },
  components: {
    DataTable,
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },

  methods: {
    formateData() {
      let ObjectTemp = {};
      let ArrayTemp = [];
      this.tb.forEach((item, index) => {
        ObjectTemp = {
          _id:item._id,
          __key: index,
          __formatedkey: index,
          indexno: index + 1,
          reference_detail: item.reference_detail || [],
          glcode: item.glcode,
          type: item.type,
          name: item.name,
          location: this.locationfilter(item.location_detail),
          location_id: item.location,
          category: item.category,
          subcategory: item.subcategory,
          opening_amount: (item.opening_amount || 0).toFixed(0),
          transaction: (item.transaction || 0).toFixed(0),
          audit_entries: (item.audit_entries || 0).toFixed(0),
          closing_amount: (item.closing_amount || 0).toFixed(0),
          created_at: item.created_at,
          status: item.status,
          stage: item.stage,
        };
        ArrayTemp.push(ObjectTemp);
      });
      this.formatedData = ArrayTemp;
    },
    refreshData() {
      this.id = this.$route.params.id || "";
      this.userid = this.$store.state.user.id;
      this.getData();
    },
    getData() {
      this.loading = true;
      return this.axios
        .post("/v2/financialclose/analysis/getdetails/" + this.id)
        .then((dt) => {
          if (dt.data.status === "success") {
            let data = dt.data.data[0] || {};
            this.metadata = data.metadata || {};
            this.$store.commit("setProjectName", data.metadata);
            this.stage = this.metadata.stage;
            this.tb = data.tb || [];
            if(this.tb.length <= 0){
              this.isFirst=true;
            }else{
              
              this.formateData();
              let tbtotals = {};
              for (const i of this.tb) {
                for (const j of i.reference_detail || []) {
                  tbtotals[j.ref] = tbtotals[j.ref] || { total: 0, items: [] };
                  tbtotals[j.ref].items.push(i);
                  tbtotals[j.ref].total =
                    tbtotals[j.ref].total + i.closing_amount || 0;
                }
              }
              this.pbcdata = {};
              let pbccount = {};
              for (const i of data.pbcitems || []) {
                this.pbcdata[i._id] = i;
                for (const j of i.reference || []) {
                  pbccount[j] = pbccount[j] || { total: 0, completed: 0 };
                  pbccount[j].total++;
                  if (i.stage === 2) pbccount[j].completed++;
                }
              }

              this.checklist = data.checklist || [];
              for (const x of this.checklist) {
                this.reflist.push(x.ref);
                x.__pbccount = pbccount[x._id] || {};
                if (pbccount[x._id]) console.log(pbccount[x._id]);
                x.__tbtotals = tbtotals[x.ref] || {};
              }
              this.summary = data.summary || {};
            }
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .then(() => {
          return this.axios.post("/v2/financialclose/analysis/getformdata");
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.formdata = dt.data.data[0] || {};
            this.userlist = {};
            let colors = this.$nova.getColors(
              (this.formdata.users || []).length,
              0.2
            );
            // console.log(colors);
            for (let i = 0; i < (this.formdata.users || []).length; i++) {
              const el = (this.formdata.users || [])[i];
              this.userlist[el._id] = el;
              this.userlist[el._id].color = colors[i];
            }
          } else throw new Error(dt.data.message);
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    readTBFile(key) {
      let file = this.tbfile;
      if ((file || {}).name && (this.fileprocessing[key] || 0) === 0) {
        this.fileprocessing[key] = 1;
        this.tbuploaddata = [];
        let ext = ((file || {}).name || "").split(".").pop();
        let possibleglcodes = [];
        let checklistrefs = {};
        Promise.resolve()
          .then(() => {
            return this.axios.post(
              "/v2/financialclose/analysis/getpossibleglcodes"
            );
          })
          .then((dt) => {
            if (dt.data.status === "success") {
              for (const i of dt.data.data) {
                possibleglcodes.push(i.glcode);
                checklistrefs[i.glcode] = i.reference || [];
              }
            } else throw Error("Error reading glcodes");
            if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1)
              throw new "Only xlsx,xls,xlsm,xlsb file extension supported"();
            return this.$nova.readExcelFile(file);
          })
          .then((dt) => {
            if (
              JSON.stringify(dt[0] || []) ===
              JSON.stringify([
                "GL Code",
                "Opening Amount",
                "Transaction",
                "Audit Entries",
                "Closing Amount",
                "Location"
              ])
            ) {
              let grouplist = [];
              for (let i = 0; i < dt.length; i++) {
                if (i > 0) {
                  const el = dt[i];
                  let gname = (el[0] || "").toString();
                  if (gname !== "" && grouplist.indexOf(gname) === -1)
                    grouplist.push(gname);
                }
              }
              let groupdata = {};
              let grouporder = [];
              let errordata = {};
              this.tberrorflag = false;
              let gstartnum = 1;
              for (let i = 0; i < dt.length; i++) {
                if (i > 0) {
                  const el = dt[i];
                  let gname = "".toString();
                  if (gname === "") {
                    let newgname = "g" + (gstartnum || "").toString();
                    while (grouplist.indexOf(newgname) !== -1) {
                      newgname = "g" + (gstartnum || "").toString();
                      gstartnum = gstartnum + 1;
                    }
                    gname = newgname;
                    if (grouplist.indexOf(gname) === -1) grouplist.push(gname);
                  }
                  groupdata[gname] = groupdata[gname] || [];
                  groupdata[gname].push({
                    group: gname,
                    // reference: checklistrefs[el[0]] || [],
                    glcode: el[0],
                    opening_amount: el[1],
                    transaction: el[2],
                    audit_entries: el[3],
                    closing_amount: el[4],
                    location: el[5]
                  });
                  if (gname !== "" && grouplist.indexOf(gname) === -1)
                    grouplist.push(gname);
                  if (grouporder.indexOf(gname) === -1) grouporder.push(gname);
                  errordata[gname] = errordata[gname] || [];
                  let errors = ["", "", "","",""];
                  let glcode = (el[0] || "").toString();
                  let closing_amount =el[4] || ""
                  if (glcode === "") errors[1] = "GL Code is required";
                  else if (possibleglcodes.indexOf(glcode) === -1)
                    errors[1] = "GL Code not found in Chart of Accounts";
                  if(!closing_amount) errors[4] = "Closing amount requried"
                  if (errors.join("") !== "") this.tberrorflag = true;
                  errordata[gname].push(errors);
                }
              }
              this.tbuploaddata = [];
              this.tbdataerror = [];

              for (const i of grouporder) {
                this.tbuploaddata.push({ group: i, data: groupdata[i] });
                this.tbdataerror.push(errordata[i]);
              }
              this.tbdialog = true;
            } else
              throw Error(
                "Columns 'GL Code','Opening Amount','Transaction','Audit Entries','Closing Amount','Location' are required in the same order"
               );
          })
          .catch((err) => {
            this.$store.commit(
              "sbError",
              err.message || err || "Unknown error!"
            );
            console.log(err);
          })
          .finally(() => {
            this.tbfile = null;
            this.fileprocessing[key] = 0;
          });
      }
    },
    uploadTBData() {
      this.tbloading = true;
      let ndt = [];
      for (const i of this.tbuploaddata) {
        ndt.push(i.data[0]);
      }

      this.axios
        .post("/v2/financialclose/analysis/bulkaddtb/" + this.id, { data: ndt })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.tbdialog = false;
            this.tberrorflag=false
            this.refreshData();
            this.$store.commit("sbSuccess", "TB uploaded successfully");
          } else throw dt.data.message || "Error uploading Trial Balance";
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.tbloading = false;
        });
    },
    addEditForm(item){
      this.tasklistitem = { ...item };
      this.tasklistdilog = true;
    },
    openSelectGlDialog(selectitem,location){
    this.selectgldialog = true;
    this.selectgldialogloading = true;
    const locationid = location && location.length > 0 ? location[0] : "axyz";
    console.log(locationid,"locationidlocationidlocationidlocationidlocationid");
      this.axios
        .post("v2/financialclose/analysis/getlocationbasedtasklist/" + this.id + "/c/" + locationid)
        .then((dt) => {
            if (dt.data.status === "success") {
              this.selectgldialogloading = false;
              this.locationtaskList =  dt.data.data.map((item)=>{
                item.displayText= item.ref+" - "+item.description
                return item
              })
            } else throw new Error(dt.data.message);
        })
        .then(() =>{
          this.selectgls = selectitem.map(item => item._id);
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
          
        })
        .finally(() => {
          this.selectgldialog = true;
        });
    },
    addSelectedGls(){
      this.tasklistitem.reference_detail = [];
       const filteredItems = this.locationtaskList.filter(item => this.selectgls.includes(item._id));
       console.log(filteredItems,"filteredItems");
     // let selectarr = [];
      for (let item of filteredItems) {
        let selectObj = { ref: item.ref, description: item.description, _id: item._id };
        this.selectRefIds.push(item._id);
        this.tasklistitem.reference_detail.push(selectObj);
      }
      //this.tasklistitem.reference_detail = selectarr;
      this.selectgldialog = false;

    },
  addUpdateGls(gllist, trialid) {
  const gllistldetails = gllist.map(list => list._id);
  const tempObject = { reference: gllistldetails };
  this.tasklistdilogloading = true;
  this.axios
    .post(`/v2/financialclose/analysis/edittrialbalance/${this.id}/c/${trialid}`, { data: tempObject })
    .then((dt) => {
      if (dt.data.status === "success") {
        this.$store.commit("sbSuccess", "Update successful");
        this.tasklistdilog = false;
        this.refreshData();
      } else {
        throw new Error(dt.data.message);
      }
    })
    .catch((err) => {
      this.$store.commit("sbError", err.message || "Unknown error!");
    })
    .finally(() => {
      this.tasklistdilogloading = false;
    });
},
    removeRef(item){
        let indexItem = this.tasklistitem.reference_detail.indexOf(item);
        this.tasklistitem.reference_detail.splice(indexItem, 1);
        for (const item of this.tasklistitem.reference_detail || [] ) {
          this.selectRefIds.push(item._id);
      }
    },
     locationfilter(item){
       return item.map(header => header.name);
    },
    setUpTrailBalance() {
      this.isFirst = false;
    },
  },
  computed: {
    closingbalancetotal() {
      return this.formatedData.reduce((total, item) => total + parseInt(item.closing_amount), 0);
    },
    // formattedtbtabledata: function () {
    //   let dta = [];
    //   for (let i = 0; i < (this.tb || []).length; i++) {
    //     const el = this.tb[i];
    //     dta.push({
    //       __key: i,
    //       __formatedkey: i,
    //       indexno: i + 1,
    //       reference_detail: el.reference_detail || [],
    //       glcode: el.glcode,
    //       type: el.type,
    //       name: el.name,
    //       category: el.category,
    //       subcategory: el.subcategory,
    //       opening_amount: (el.opening_amount || 0).toFixed(0),
    //       transaction: (el.transaction || 0).toFixed(0),
    //       audit_entries: (el.audit_entries || 0).toFixed(0),
    //       closing_amount: (el.closing_amount || 0).toFixed(0),
    //       created_at: el.created_at,
    //       status: el.status,
    //       stage: el.stage,
    //     });
    //   }
    //   return dta;
    // },
    groupedItems() {
      const grouped = {};
      this.checklist.forEach(item => {
        if (!grouped[item.category]) {
          grouped[item.category] = [];
        }
        grouped[item.category].push(item);
      });
      return grouped;
    },
  },
  watch: {},
};
</script>

